import type { ReactElement } from 'react';
import type { GetServerSideProps, InferGetServerSidePropsType, Redirect } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCMSPageData } from 'services/getCMSPageData';
import { PageType } from '@storefront/lib-global';
import { MasterLayout } from '@storefront/lib-global/globalComponents/masterLayout';
import { Meta } from '@storefront/lib-global/globalComponents/seo/Meta';
import { logger } from '@storefront/lib-global/utils/logger';
import { setCacheTagHeaderByType } from '@storefront/lib-global/utils/setCacheTagHeader';
import { setCustomCacheHeader } from '@storefront/lib-global/utils/setCustomCacheHeader';

/* eslint-disable promise/prefer-await-to-then */
const URLManager = dynamic(() => import('components/urlManager/URLManager').then((mod) => mod.URLManager));
/* eslint-disable promise/prefer-await-to-then */

const parseJson = (jsonString = '') => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON: ', error);
    return null;
  }
};

export default function CMSPage({
  urlManagerContent,
  handlebarComponents,
  breadcrumbData,
  host,
  cmsPageData,
}: InferGetServerSidePropsType<typeof getServerSideProps>): ReactElement {
  const { asPath } = useRouter();

  return (
    <>
      <Meta
        isContent
        host={host}
        urlManagerContent={urlManagerContent}
        pageType={asPath === '/' ? PageType.HOMEPAGE : PageType.CMS}
        cmsPageData={cmsPageData}
      />
      <MasterLayout>
        <URLManager {...{ breadcrumbData, handlebarComponents, ...parseJson(urlManagerContent) }} />
      </MasterLayout>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ query, locale, req, res }) => {
  const { host } = req.headers;

  const [{ notFound, props, redirect }, translations] = await Promise.all([
    getCMSPageData({
      slug: query.slug as string[] | undefined,
      locale,
    }),
    serverSideTranslations(locale || 'en', [
      'app-main-common',
      'lib-content-common',
      'lib-global-common',
      'lib-products-common',
      'lib-appointments-common',
      'lib-csa-common',
      'lib-global-countries-common',
    ]),
  ]);

  logger.debug(
    {
      message: 'getCMSPageData result',
      notFound,
      redirect: redirect ? JSON.stringify(redirect) : null,
      propsKeys: props,
      slug: query.slug,
      locale,
    },
    'getCMSPageData pages/slug',
  );

  if (notFound) {
    // check if it works and shows up correctly
    logger.warn(
      {
        message: '404 Not Found',
        slug: query.slug,
        locale,
      },
      'getCMSPageData',
    );
    return {
      notFound,
    };
  }

  if (redirect) {
    logger.debug(
      {
        message: 'redirect',
        locale,
        redirect: JSON.stringify(redirect),
      },
      'getCMSPageData',
    );
    return {
      redirect: redirect as Redirect,
    };
  }

  const protocol = req.headers.referer?.split('://')[0] || 'https';
  const serverUrl = `${protocol}://${req.headers.host}${req.url}`;

  // cache for 2 hours, and show expired cache for maximum of 30 minutes
  setCustomCacheHeader(res, { isPublic: true, maxAge: 7200, staleWhileRevalidate: 1800 });

  setCacheTagHeaderByType(res, locale, {}, query.slug as string[] | undefined);

  return {
    props: {
      ...props,
      ...translations,
      host,
      locale,
      serverUrl,
    },
  };
};
